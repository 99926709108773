<template>
  <div class="myInfo">
    <h2>个人信息</h2>
    <el-form v-if="isEdit" label-position="top" :model="myInfoForm" :rules="rules" ref="myInfoForm">
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="myInfoForm.name"></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱：" prop="email">
        <el-input v-model="myInfoForm.email"></el-input>
      </el-form-item>
      <el-form-item label="电话号码：" prop="phoneNumber">
        <el-input v-model="myInfoForm.phoneNumber">
          <el-button @click="getCode" type="text" slot="suffix" class="code"
            :disabled="!myInfoForm.phoneNumber||!sendAuthCode">
            <span v-if="sendAuthCode">获得验证码</span>
            <span v-else>{{authTime}}s 后重新获得</span>
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码：" prop="code">
        <el-input v-model="myInfoForm.code"></el-input>
      </el-form-item>
      <el-form-item style="text-align: center;">
        <p style="margin-top: 10px;">
          <el-button @click="submitForm" type="primary" size="medium" style="width: 150px">
            保存信息</el-button>
        </p>
      </el-form-item>
    </el-form>
    <div class="detail-myInfo" v-else>
      <ul style="margin-bottom: 20px">
        <li><span class="label">用户名：</span><span>{{userInfo&&userInfo.username}}</span></li>
        <!-- <li><span class="label">姓名：</span><span>{{userInfo&&userInfo.firstName}}</span></li>
        <li><span class="label">电子邮箱：</span><span>{{userInfo&&userInfo.email}}</span></li> -->
        <li><span class="label">电话号码：</span><span>{{userInfo&&userInfo.attributes.phone&&userInfo.attributes.phone[0]}}</span></li>

      </ul>
      <!-- <el-button @click="edit" type="primary" size="medium">编辑信息</el-button> -->
    </div>
  </div>

</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MyInfo",
  data() {
    return {
      isEdit: false,
      myInfoForm: {
        name: "",
        email: "",
        phoneNumber: "",
        code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        phoneNumber: [
          { required: true, message: "请输入电话", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      // 验证码
      sendAuthCode: true /*布尔值，通过v-if控制显示‘获取按钮’还是‘倒计时’ */,
      authTime: 0 /*倒计时 计数器*/,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    // console.log(this.userInfo);
  },
  methods: {
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit() {
      this.isEdit = true;
      this.myInfoForm.name = this.userInfo.firstName;
      this.myInfoForm.email = this.userInfo.email;
      this.myInfoForm.phoneNumber = this.userInfo.attributes?.phone&&this.userInfo.attributes?.phone[0];
    },
    // 验证
    getCode() {
      this.sendAuthCode = false;
      this.authTime = 60;
      let authTimetimer = setInterval(() => {
        this.authTime--;
        if (this.authTime <= 0) {
          this.sendAuthCode = true;
          clearInterval(authTimetimer);
        }
      }, 1000);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.myInfo {
  width: 70%;
  padding: 20px 10px;
  .detail-myInfo {
    line-height: 40px;
    font-size: 14px;
    .label {
      font-weight: bold;
    }
  }
  .code {
    margin-right: 5px;
  }
}
</style>
