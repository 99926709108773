<template>
  <div class="account-center">
    <el-row>
      <el-col :span="5">
        <div class="account-left">
          <div class="avatar">
            <el-avatar icon="el-icon-user" class="user-avatar" :size="50">
            </el-avatar>
            <h3>{{userInfo&&userInfo.firstName}}</h3>
            <p>{{userInfo&&userInfo.email}}</p>
          </div>
          <ul class="menu">
            <li @click="handleSelect(item.components)" v-for="(item,index) in leftMenu" :key="index"
              :class="nowComponent===item.components&&'active'">
              <span>{{item.name}}</span>
            </li>
            <li>
          <el-button type="text" @click="dialogVisible = true">注销并删除账号</el-button>
        </li>
          </ul>
        </div>

      </el-col>
      <el-col :span="19">
        <div class="account-right">
          <component :is="nowComponent"></component>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="确定要注销账户吗？"
      :visible.sync="dialogVisible"
      width="30%">
      <el-radio-group v-model="closeReason" class="my-radio">
        <el-radio label="相同账号过多">相同账号过多</el-radio>
        <el-radio label="系统不好用">系统不好用</el-radio>
        <el-radio label="不再使用社区">不再使用社区</el-radio>
        <el-radio label="营销过度骚扰">营销过度骚扰</el-radio>
        <el-radio label="其他">其他
          <el-input  size="small" v-model="reason" style="width:70%; margin-left: 10px;"></el-input></el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading"  @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="submitClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { MyInfo, UpdatePassword } from "./components";
import { closeAccount } from "@/api/account";
import { mapGetters } from "vuex";
export default {
  name: "accountCenter",
  components: {
    MyInfo,
    UpdatePassword,
  },

  data() {
    return {
      leftMenu: [
        {
          name: "个人信息",
          components: "MyInfo",
        },
        // {
        //   name: "修改密码",
        //   components: "UpdatePassword",
        // },
      ],
      nowComponent: "MyInfo",
      dialogVisible: false,
      closeReason: '',
      reason:'',
      loading:false
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    dialogVisible(newValue) {
      if(!newValue) {
        this.closeReason = ''
        this.reason = ''
      }
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    handleSelect(components) {
      this.nowComponent = components;
    },
    getUserInfo() {
      this.$store.dispatch("user/getKeycloakInfo");
    },
    submitClose(){
      this.loading = true
      const reason = this.reason||this.closeReason

      closeAccount(reason).then(() => {
        this.$success("您的账户已注销");
        this.dialogVisible= false
        this.loading = false
        setTimeout(()=>{
          location.reload()
        }, 500)
      }) .catch(() => {
        this.dialogVisible= false
        this.loading = false
        });
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.account-center {
  background: #f8f9fc;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  .account-left {
    .avatar {
      color: #787878;
      text-align: center;
      padding: 40px 0;
      line-height: 25px;
      p,
      h3 {
        margin: 0;
      }
      .user-avatar {
        font-size: 28px !important;
        background: #15759e !important;
      }
    }
    .menu {
      line-height: 35px;
      li {
        cursor: pointer;
        padding: 5px 0;
        text-align: center;
        &.active {
          background-color: var(--color-primary);
          color: #ffffff;
        }
      }
    }
  }

  .account-right {
    padding: 0 20px;
    background: #ffffff;
    min-height: 500px;
    border-left: 1px solid #eaeaea;
  }
  .my-radio {
    .el-radio {
      display: block;
      line-height: 35px;
    }
  }
}
</style>
